$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    generateShowModal();
    generateAjaxButton();
    generateAjaxSubmit();
    validationImageBeforeUpload();
    processExport();

    function generateShowModal()
    {
        $(document).on('click', "#load-modal", function(e){

            e.preventDefault();

            let url = $(this).data('url');

            $('.modal_container').html("");

            if($('.modal').length) {
                $('.modal').remove();
            }

            loadContentModalBackend(url);
        });
    }

    loadContentModalBackend = (url) => {
        $.ajax({
            type: 'get',
            url: url,
            contentType: "json",
            beforeSend: function (e) {

            },
            success: function (data) {
                $('.modal_container').append(data.html);

                $('.modal').modal({
                    keyboard: false,
                    backdrop: 'static',
                    focus: true,
                    show: true
                });

                generateAjaxSubmitModal();

            },
            error: function (response) {
                //icon, title, text, confirmButton, cancelCutton, timer
                _alertAjax('error', '', response.message, true, true, false);
            }

        });
    }

    function generateAjaxSubmitModal(){
        // Send Form Data Text and File
        let lastClick = 0;
        $(document).on('click', '#modal-submit-button', function(e){
            e.preventDefault();
            var button = $(this);
            button.addClass('disabled');
            const thisClick = Date.now();
            
            var submitForm  = document.getElementById('submit-ajax-form');
            var data = new FormData(submitForm);

            var method = submitForm.getAttribute('method');
            var url = submitForm.getAttribute('action');

            if (thisClick - lastClick > 3000) {
                $.ajax({
                    type: method,
                    enctype: 'multipart/form-data',
                    url: url,
                    data: data,
                    processData: false,
                    contentType: false,
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        Swal.fire({
                            icon: "info",
                            title: "Harap menunggu",
                            html: "Form sedang diproses.",
                            showConfirmButton: false,
                            timer: false
                        });
                    },

                }).done( function(response){
                    resetFeedback();
                    $('.modal').modal('hide');
                    $('.modal-backdrop').remove();
                    $('.modal_container').html("");
                    //icon, title, text, confirmButton, cancelCutton, timer
                    button.removeClass('disabled');
                    Swal.close();

                    _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);
                }).fail( function(xhr, status, error){
                    resetFeedback();
                    button.removeClass('disabled');
                    Swal.close();

                    getFeedback(xhr.responseJSON.errors);
                });

            } else {
                Swal.close();
                button.removeClass('disabled');

                _alertAjax('error', 'Harap menunggu', 'Tombol terkunci selama 3 detik.', true, false, false);
            }

            lastClick = thisClick;

        });
    }

    function generateAjaxSubmit(){
        // Send Form Data Text and File
        let lastClick = 0;
        $(document).on('click', '#submit-button', function(e){
            e.preventDefault();
            var button = $(this);
            button.addClass('disabled');
            const thisClick = Date.now();
            
            var submitForm  = document.getElementById('submit-ajax-form');
            var data = new FormData(submitForm);

            var method = submitForm.getAttribute('method');
            var url = submitForm.getAttribute('action');

            if (thisClick - lastClick > 3000) {
                $.ajax({
                    type: method,
                    enctype: 'multipart/form-data',
                    url: url,
                    data: data,
                    processData: false,
                    contentType: false,
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        Swal.fire({
                            icon: "info",
                            title: "Harap menunggu",
                            html: "Form sedang diproses.",
                            showConfirmButton: false,
                            timer: false
                        });
                    },

                }).done( function(response){
                    resetFeedback();
                    $('.modal_container').html("");
                    //icon, title, text, confirmButton, cancelCutton, timer
                    button.removeClass('disabled');
                    Swal.close();
                    
                    if(response.forceReload){
                        
                        _alert(response.status, response.title, response.message, response.confirm, response.cancel, response.timer, response.redirectUrl);
                    
                    }else{
                        
                        _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);
                    
                    }

                }).fail( function(xhr, status, error){
                    resetFeedback();
                    button.removeClass('disabled');
                    Swal.close();

                    getFeedback(xhr.responseJSON.errors);
                });

            } else {
                Swal.close();
                button.removeClass('disabled');

                _alertAjax('error', 'Harap menunggu', 'Tombol terkunci selama 3 detik.', true, false, false);
            }

            lastClick = thisClick;

        });
    }

    function generateAjaxButton(){
        let lastClick = 0;
        $(document).on('click', '.ajax-button', function(e){
            e.preventDefault();
            var button = $(this);
            var url = button.data('url');
            var message = button.data('title');
            var method = button.data('method');

            button.addClass('disabled');
            const thisClick = Date.now();

            if (thisClick - lastClick > 3000) {
                if (confirm(message)) {
                    $.ajax({
                        type: method,
                        url: url,
                        contentType: "application/json",
                        dataType: 'json',
                        cache: false,
                        beforeSend: function(e){
                            Swal.fire({
                                icon: "info",
                                title: "Harap menunggu",
                                html: "Form sedang diproses.",
                                showConfirmButton: false,
                                timer: false
                            });

                        }
                    
                    }).done( function(response){
                        button.removeClass('disabled');
                        Swal.close();
                        
                        //icon, title, text, confirmButton, cancelCutton, timer
                        _alertAjax(response.status, response.title, response.message, false, false, 1500);

                    }).fail( function(xhr, status, error){
                        button.removeClass('disabled');
                        Swal.close();

                        //icon, title, text, confirmButton, cancelCutton, timer
                        _alertAjax(status, '', error, true, true, false);
                    });
                }
            } else {
                Swal.close();
                button.removeClass('disabled');

                _alertAjax('error', 'Harap menunggu', 'Tombol terkunci selama 3 detik.', true, false, false);
            }

            lastClick = thisClick;
            
        });
    }

    function validationImageBeforeUpload()
    {
         $(document).on('input', '.custom-file-input', function(){

            var obj = $(this);
            var filePath = obj.val();
            var objName = obj.attr('name');

            var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;

            if (!allowedExtensions.exec(filePath)) {
               _alertAjax('error', '', 'Jenis File tidak diijinkan', true, true, false);
               obj.value = '';
               return false;
            }else{
            
               // Image preview
               if (this.files && this.files[0]) {
                  var reader = new FileReader();
                  reader.onload = (e) => {
                    $('#photoPreview_'+objName).attr('src', e.target.result);
                  };
                     
                  reader.readAsDataURL(this.files[0]);
               }

            }

      });
    }

    function processExport()
    {
        $(document).on('click', '.export-excel', function(e){
            e.preventDefault();
            var obj = $(this);
            obj.addClass('disabled');

            var url = obj.data('url');
            var method = obj.data('method');

            $.ajax({
                url: url,
                type: method,
                xhrFields: {
                    responseType: 'blob',
                },
                success: function (response, status, xhr){
                    obj.removeClass('disabled');

                    var disposition = xhr.getResponseHeader('content-disposition');
                    var matches = /"([^"]*)"/.exec(disposition);
                    var filename = (matches != null && matches[1] ? matches[1] : moment()+'.csv');

                    var blob = new Blob([response], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });

                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            });
        });
    }

    getFeedback = (errors) => {
        $.each(errors, function (error, e) {
            $("input[name='" + error + "']").addClass('is-invalid');
            var e_msg = "";
            if (e.length > 1) {
                $.each(e, function (error, msg) {
                    e_msg += "<i class='fas fa-angle-right'></i> " + msg + "<br>";
                });
            } else {
                e_msg = e;
            }

            $('.error-' + error).html(e_msg);
        });
    }

    resetFeedback = () => {
        $(".form-control").removeClass('is-invalid');
        $(".form-note").html('');
    }

});